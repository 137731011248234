import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout
    sermonSrc="https://www.youtube.com/embed/HBQlrfOwA00"
    notesSrc="http://bible.com/events/12072563"
    notesText="Sermon Notes"
    bibleGroupSrc="https://www.docdroid.net/7ziusj9/bible-group-homework-5-10-pdf"
    bibleGroupText="Bible Group Homework"
  >
    <SEO title="When Pigs Fly - Miracles of Provision" />
  </Layout>
)

export default SermonPost
